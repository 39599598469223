body{
    background:    #f1f4f5 !important;

}
.dashboard-column-containers{
    border-radius: 0.25rem;
    background-color: #fff;
    padding: 10px;
    border:1px solid #ccc;

}

.campaign-row-message{
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    
}

.round{
    border-radius: 50%;
    position: relative;
    top: -10%;
}

.send-button{
    height: 120px;
    border-radius: 60px !important;
    font-size: 30px !important;
    letter-spacing: 5px;
    font-weight: 300 !important;
}

.no-underline{
    text-decoration: none;
}

.no-underline:hover{
    text-decoration: none;
}

h3{
    font-weight: 300;
}

h5{
    color: #002984;
}

label{
    color: #3949ab;
    /* margin-right: 5px; */
}

label b{
    font-weight: 500;
}

.right{
    float: right;
    display: block;
}