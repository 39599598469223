.right{
  float:right;
}

#title{
  font-weight: 700;
}

.dropzone{
  height: 20%;
  background: white;
  margin:20px;
  padding: 20px;
  max-height: 30vh;
  overflow: auto;
}

.dropzone img{
  max-height: 20%;
  /* width:auto; */
}

.media-attachment-buttons{
  background: none;
  /* width: 100%; */
  min-width: 10px;
  border: none;
  color:white !important;
}

.media-viewer-box{
  max-width: 50vw;
}

.attach-button{
  position: absolute;
  bottom:7px;
  right:-50px;
  z-index:100;
}

.message-input-row{
  width: 100%;
  display: flex;
  flex-flow: row;
}

.message-input-area{
  position: relative;
  flex-grow:1;

}

.message-input-buttons{
  width: 240px;

}

.btn-light {
  border-color: transparent;
}

.media-viewer-modal{
  background-color: rgba(0,0,0,0.8);
  border-radius: 0px !important;
}

.media-viewer-modal .modal-body{
  padding:0px;
  background: none;

}

.media-viewer-modal .modal-content{
  padding:0px;
  background: none;
}

.media-viewer-modal .close-button{
  font-size: 20px;
  position: absolute;
  right:-10px;
  top:-10px;
  background-color: black;
  border-radius: 50%;
  padding: 0px;
  color: white;
}

.dashboard-column-containers .dropzone, .agent-click-media-viewer {
  max-height: unset;
  height: unset;
}