.left{
  float: left;
}

.right{
  float: right;
}

.img-circle{
  border-radius: 50%;
  height: calc(1.5em + 0.75rem + 14px);
}

.round{
  border-radius: 50%;
  min-width: 40px;
}

.message-input{
  border-radius: 20px;
  max-height: 100px;
  display: inline;
  width: 98%;
  border-color: #bbb;
  position: relative;
  border-width: 1px !important;
  padding: 10px !important;
  border-style: double;

}

.message-input:focus{
  outline: none;
  outline-width: none;
  box-shadow: none;
  padding-right: 70px !important;

}

.hidden{
  visibility: none;
  position: absolute;
}

.slider {
overflow-y: hidden;
max-height: 500px; /* approximate max height */

transition-property: all;
transition-duration: .5s;
transition-timing-function: cubic-bezier(0, 0.3, 0.5, 1.5);
}

.slider.closed {
max-height: 0;
}

.slider.closed {
max-height: 0;
}