.main-container{
  height: 100vh;
  overflow: hidden;
}

.left-nav{
  height: 100%;
  /* background-color: #dfdfdf; */
  padding: 0px;
}

.left-nav>.container>.form-control{
  padding: 25px;

}

input.empty {
 font-family:Arial, FontAwesome;
 border-radius: 50px !important;
}

.round{
  border-radius: 50% !important;
}



/* .dropleft .dropdown-menu, .dropup .dropdown-menu{
-webkit-transition: all 0.1s ease-in;
-moz-transition: all 0.1s ease-in;
-ms-transition: all 0.1s ease-in;
-o-transition: all 0.1s ease-in;
transition:all 0.1s ease-in;

overflow: hidden;
opacity: 0;
} */

/* .dropleft.show .dropdown-menu, .dropup.show .dropdown-menu{
max-height: 300px;
opacity:1
} */

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.unread-message{
  background-color: antiquewhite !important;
}

.tutorial-container{
  height: 100vh;
  width: 100%;
  padding: 3%;
}

.tutorial{
  width: 100%;
}

.converstion-list{
  height: calc(100vh  - 50px);
  overflow-y: hidden;
  overflow-x:hidden;
}

button:active {
  outline: none;
  border: none;
  }
  
  button:focus {outline:0;}
  
.contact-list{

}

.book-container{
height:81vh;
}

.no-decoration{
text-decoration: none !important;
}

.close-btn{
font-weight: 700;
line-height: 1;
color: #000;
text-shadow: 0 1px 0 #fff;

}